import { authenticationManager } from '@/util/authenticationManager';
import { useEffect, useRef } from 'react';
import store from 'store2';

const SignInCallback: React.FC = () => {
  const first = useRef(true);
  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && first.current) {
      first.current = false;
      return;
    }
    (async () => {
      await authenticationManager.userManager.signInCallback();
      const savedPath = store.session.get('path') || '/';
      window.history.pushState('', '', savedPath);
      window.location.pathname = savedPath.split('?')[0];
    })();
  }, []);
  return <></>;
};

export default SignInCallback;
