import { ReleaseNote, useReleaseNotes } from '@/services/documents';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount, useUnmount } from 'react-use';
import Application from './Application';
import Overview from './Overview';
import ReferenceDesign from './ReferenceDesign';
import Top from './Top';
import Update from './Update';

const PilotAutoIndex: React.FC = () => {
  const { i18n } = useTranslation();
  const { getReleaseNotes } = useReleaseNotes();
  const [releaseNotes, setReleaseNotes] = useState<ReleaseNote[]>([]);

  useMount(() => {
    document.body.style.backgroundColor = '#f0f4ff';
  });

  useUnmount(() => {
    document.body.style.backgroundColor = 'transparent';
  });

  useEffect(() => {
    (async () => {
      const releaseNotesRes = await getReleaseNotes('release-notes/');
      const currentLanguageReleaseNotesData = releaseNotesRes[i18n.language];
      if (
        currentLanguageReleaseNotesData &&
        currentLanguageReleaseNotesData.release_notes.length
      ) {
        setReleaseNotes(
          currentLanguageReleaseNotesData.release_notes.slice(0, 6),
        );
      }
    })();
  }, [getReleaseNotes, i18n.language]);

  return (
    <>
      <Top />
      <Update releaseNotes={releaseNotes} />
      <Overview />
      <ReferenceDesign />
      <Application />
    </>
  );
};

export default PilotAutoIndex;
