import { authenticationManager } from '@/util/authenticationManager';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SignOutCallback: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    authenticationManager.userManager.signOutCallback().then(() => {
      navigate('/', { replace: true });
    });
  }, [navigate]);
  return <></>;
};

export default SignOutCallback;
